import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Slide, toast } from 'react-toastify';
import { saveAccountInfoFlag } from "../../actions/AccountAction";
import { saveClientNmbr, saveLoginId, saveSchemeType } from "../../actions/EmployeeLoginAction";
import { saveCurrentLoadedPageOnUI } from "../../actions/LandingAction";
import { savePisysToken } from "../../actions/TokenAction";
import { saveAuthenticationFlag } from "../../actions/TwoFactorAuthenticationAction";
import profileLogo from "../../assets/Profile.png";
import Toast from '../../Components/Toast';
import "../../css/SuperLogonLogin.css";
import LoginHeader from "../../SharedComponents/LoginHeader";
import SpinnerButton from "../../SharedComponents/SpinnerButton";
import { COBRAND, HKAIIB, schemeS800, SMP } from "../../translations/common/commonConstants";
import { AccountUtils, SuperLogonUtils } from "../../utils";
import API from "../../API/SuperLogon/SuperLogon";
import AccountUtilsCobrand from "../../utils/AccountUtilsCobrand";

const SuperLogonLogin = (props) => {
    const { t } = useTranslation("common");
    const history = useHistory();
    const [scheme, setScheme] = useState();
    const [loginID, setLoginID] = useState();
    const [serviceCallFlag, setServiceCallFlag] = useState(false);
    const [redirectionFlag, setRedirectionFlag] = useState(false);
    const [accessCodeError, setAccessCodeError] = useState(false);
    const schemeList = [t("empLogin.selectScheme"), "S800 & ORSO", HKAIIB, COBRAND];
    const { hash } = useLocation();
    const codeVerifier = props?.ssoData?.codeVerifier;

    useEffect(() => {
        resetReduxToDefaults(props);
        setServiceCallFlag(true);
        const schemeName = scheme?.includes("S800 & ORSO") ? schemeS800 : scheme;
        const searchParams = new URLSearchParams(hash);
        const authorizationCode = searchParams.get('#code') ?? '';

        if (hash?.includes("code")) {
            SuperLogonUtils.authentication(authorizationCode, codeVerifier).then(async (response) => {
                setServiceCallFlag(false);
                const responseData = JSON.parse(response.data);
                props.dispatch(savePisysToken(responseData?.access_token));
                props.dispatch(saveLoginId(loginID));
                props.dispatch(saveAuthenticationFlag(true));
                props.dispatch(saveClientNmbr(loginID));
                props.dispatch(saveSchemeType(schemeName));
            }).catch(() => {
                setServiceCallFlag(false);
                history.push("/superLogon/signIn");
                sendNotification('something-went-wrong');
            });
        } else {
            setAccessCodeError(true);
        }
    }, []);

    const resetReduxToDefaults = (props) => {
        props.dispatch(saveCurrentLoadedPageOnUI(''));
    };

    const toastOptions = {
        position: 'top-center',
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        rtl: false,
        closeButton: false,
    };

    const sendNotification = (type) => { toast(<Toast type={type} />, toastOptions); };

    const submit = async () => {
        const schemeName = scheme?.includes("S800 & ORSO") ? schemeS800 : (scheme == COBRAND ? SMP : scheme);
        setRedirectionFlag(true);
        const requestHeaders = {
            'pisys_auth_token': props.pisysToken,
            'Access-Control-Allow-Origin': '*'
        };
        let clientNmbrList = [];
        if (schemeName == SMP) {
            await API.clientSearch(requestHeaders, String(loginID)).then(async (response) => {
                clientNmbrList = response?.data?.clientNmbrList;
            }).catch(() => {
                setServiceCallFlag(false);
                sendNotification('something-went-wrong');
            });
        } else {
            clientNmbrList.push(loginID)
        }
        props.dispatch(saveSchemeType(schemeName));
        props.dispatch(saveLoginId(loginID));
        props.dispatch(saveAuthenticationFlag(true));
        props.dispatch(saveClientNmbr(clientNmbrList[0]));
        if (schemeName == SMP) {
            await AccountUtilsCobrand.saveAccountDetails(props.dispatch, schemeName, clientNmbrList[0], clientNmbrList, props.pisysToken, setServiceCallFlag, sendNotification)
                .then(() => { props.dispatch(saveAccountInfoFlag(true)); });
        } else {
            await AccountUtils.saveAccountDetails(props, schemeName, props.pisysToken, loginID, setServiceCallFlag, sendNotification)
                .then(() => { props.dispatch(saveAccountInfoFlag(true)); });
        }
        history.push({ pathname: "/account", filteredAccounts: props.quickPolicyInfo });
    };

    return (
        <React.StrictMode>
            <LoginHeader />
            <div className="main-content">
                <div className="sll-container row">
                    <label className="col-12 sll-title text-center">Super Logon Login</label>
                    <div className="col-sm-12 col-md-6 mx-auto">
                        {accessCodeError ? <><img src={profileLogo} className="mt-3 d-block mx-auto" />
                            <label className="mt-5 sll-note">You are not authorised to access this page. Please connect with your system administrator.</label>
                        </> :
                            <>
                                {serviceCallFlag ?
                                    <div className="d-flex justify-content-center">
                                        <div className="spinner-border spinner m-5" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                    :
                                    <div className="pds-card mt-4 sll-card">
                                        <form onSubmit={(e) => { e.preventDefault(); }}>
                                            <div className="col-11 mx-auto mt-4">
                                                <select id="sll-schemeSelector" value={scheme} onChange={(event) => setScheme(event.target.value)} aria-label="sll-schemes" name="sll-selectScheme" className="rsc-select select-arrow sll-dropDown">
                                                    {schemeList.map((list, i) => (
                                                        <option className="sll-dropDown" key={i} value={list}>{list}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-11 mx-auto mt-3">
                                                <input
                                                    className="sll-login-ip"
                                                    id="sll-loginid"
                                                    type="text"
                                                    name="loginid"
                                                    value={loginID}
                                                    placeholder={t("empLogin.loginidPlaceholder")}
                                                    autoComplete="off"
                                                    onChange={(event) => setLoginID(event.target.value)}
                                                />
                                            </div>
                                            <div className="col-11 mx-auto mt-3 mb-4">
                                                <button
                                                    name="login"
                                                    type="submit"
                                                    className="sll-login-btn"
                                                    disabled={!scheme || scheme == schemeList[0] || !loginID}
                                                    onClick={submit}
                                                >
                                                    <SpinnerButton serviceCallFlag={redirectionFlag} label={t("common.submit")}></SpinnerButton>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                }
                            </>}
                    </div>
                </div>
            </div>
        </React.StrictMode>
    );
};

SuperLogonLogin.propTypes = {
    props: PropTypes.any,
    dispatch: PropTypes.func,
    quickPolicyInfo: PropTypes.array,
    scheme: PropTypes.string,
    pisysToken: PropTypes.string,
    ssoData: PropTypes.object,
};
const mapStateToProps = (state) => {
    return {
        quickPolicyInfo: state.twoAccounts.quickPolicyInfo,
        scheme: state.empLogin.scheme,
        pisysToken: state.token.pisysToken,
        ssoData: state.ssoData,
    };
};
export default connect(mapStateToProps)(SuperLogonLogin);